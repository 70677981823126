import { instance } from './url'

const talk = async (data) => {
  return await instance
    .post('/talk/gpt4otest', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const feedback = async (data) => {
  return await instance
    .post('/talk/yoontest/feedback', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const hint = async (data) => {
  return await instance
    .post('/talk/yoontest/hint', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const clovatalk = async (data) => {
  return await instance
    .post('/talk/clovatalk', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const clovafeedback = async (data) => {
  return await instance
    .post('/talk/clovafeedback', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const clovahint = async (data) => {
  return await instance
    .post('/talk/clovahint', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getalldata = async () => {
  return await instance
    .get('/talk/renewal/get/data', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const editdata = async (data) => {
  return await instance
    .post('/talk/renewal/edit/data', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getprompt = async (data) => {
  return await instance
    .post('/talk/renewal/get/prompt', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const editprompt = async (data) => {
  return await instance
    .post('/talk/renewal/edit/prompt', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getTutorTopics = async () => {
  return await instance.get('/talk/tutor/topics', { credentials: true })
}

const getSelect1 = async () => {
  return await instance.get('/talk/level1/select', { credentials: true })
}

const getSelect2 = async () => {
  return await instance.get('/talk/level2/select', { credentials: true })
}

const getSelect3one = async () => {
  return await instance.get('/talk/level3/select/one', { credentials: true })
}

const getSelect3two = async () => {
  return await instance.get('/talk/level3/select/two', { credentials: true })
}

const getSelect3Three = async () => {
  return await instance.get('/talk/level3/select/three', { credentials: true })
}

const createTutorSession = async (data) => {
  return await instance.post('/talk/create/tutor/session', data, {
    credentials: true
  })
}

const getTutorPrompt = async (id) => {
  return await instance.get(`/talk/tutor/prompt2/${id}`, { credentials: true })
}

const talk2 = async (data) => {
  return await instance
    .post('/talk/gpt4otest2', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const setSession1 = async (data) => {
  return await instance
    .post('/talk/create/tutor/session/one', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const setSession2 = async (data) => {
  return await instance
    .post('/talk/create/tutor/session/two', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const setSession3 = async (data) => {
  return await instance
    .post('/talk/create/tutor/session/three', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getEndSentence = async () => {
  return await instance
    .get('/talk/class/end/sentence', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getSessionList = async () => {
  return await instance
    .get('/talk/session/list', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getSaveSession = async (root, child) => {
  return await instance
    .get(`/talk/session/history/${root}/${child}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getPresetList = async () => {
  return await instance
    .get(`/talk/preset/list`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const createPreset = async (data) => {
  return await instance
    .post(`/talk/create/preset`, data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const deletePreset = async (id) => {
  return await instance
    .get(`/talk/delete/preset/${id}`, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

export {
  getPresetList,
  createPreset,
  deletePreset,
  setSession1,
  setSession2,
  setSession3,
  talk,
  talk2,
  feedback,
  hint,
  clovatalk,
  clovafeedback,
  clovahint,
  getalldata,
  editdata,
  getprompt,
  editprompt,
  getTutorTopics,
  getSelect1,
  getSelect2,
  getSelect3one,
  getSelect3two,
  getSelect3Three,
  createTutorSession,
  getTutorPrompt,
  getEndSentence,
  getSessionList,
  getSaveSession
}
