<template>
  <div style="background-color: beige; width: 100%; height: 100%">
    <v-row style=""
      ><img
        style="left: 0; position: absolute; margin-left: 20px; margin-top: 10px"
        width="200px"
        src="../assets/images/tutorlogo2.png"
        alt=""
    /></v-row>
    <v-row justify="center">
      <div
        style="
          float: left;
          margin-right: -30px;
          transform: scale(0.95);
          margin-top: 50px;
        "
      >
        <div class="level1popup">
          <!-- level1 Setting -->
          <v-row style="margin-top: 15px">
            <div class="popup" style="margin-left: 30px">
              <p
                style="
                  font-family: notosansregular;
                  font-size: 21px;
                  margin-bottom: 40px;
                "
              >
                1. 단원 설정
              </p>
              <table
                style="
                  margin-top: -20px;
                  margin-left: 20px;
                  width: 600px;
                  border-spacing: 10px;
                "
              >
                <tr>
                  <td
                    style="
                      height: 30px;
                      font-family: notosansregular;
                      font-size: 17px;
                    "
                  >
                    학년 - 학기
                  </td>
                  <td>
                    <div
                      style="
                        width: 450px;
                        margin-left: 20px;
                        margin-top: 10px;
                        height: 40px;
                        margin-top: 1px;
                      "
                    >
                      <v-select
                        @change="gradeInfochange()"
                        :items="level1select_gradeInfo"
                        item-text="gradeInfo"
                        item-value="gradeInfo"
                        v-model="level1data.gradeInfo"
                        style="font-family: notosansregular"
                        label="학년 - 학기를 선택해주세요."
                        outlined
                        dense
                      ></v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      height: 30px;
                      font-family: notosansregular;
                      font-size: 17px;
                    "
                  >
                    과목
                  </td>
                  <td>
                    <div
                      style="
                        width: 450px;
                        margin-left: 20px;
                        margin-top: 10px;
                        height: 40px;
                        margin-top: 1px;
                      "
                    >
                      <v-select
                        @click="
                          {
                            level1select = level1selectall;
                            level1data.subject = '';
                          }
                        "
                        :disabled="!level1data.gradeInfo"
                        @change="subjectchange()"
                        :items="level1select_subject"
                        item-text="subject"
                        item-value="subject"
                        v-model="level1data.subject"
                        style="font-family: notosansregular"
                        label="과목을 선택해주세요."
                        outlined
                        dense
                      ></v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      height: 30px;
                      font-family: notosansregular;
                      font-size: 17px;
                    "
                  >
                    대단원
                  </td>
                  <td>
                    <div
                      style="
                        width: 450px;
                        margin-left: 20px;
                        margin-top: 10px;
                        height: 40px;
                        margin-top: 1px;
                      "
                    >
                      <v-select
                        @change="bigSubjectChange()"
                        :items="level1select_big"
                        item-text="bigSubject"
                        item-value="bigSubject"
                        v-model="level1data.bigSubject"
                        style="font-family: notosansregular"
                        label="대단원을 선택해주세요."
                        outlined
                        dense
                        :disabled="!level1data.subject"
                      ></v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      height: 30px;
                      font-family: notosansregular;
                      font-size: 17px;
                    "
                  >
                    중단원
                  </td>
                  <td>
                    <div
                      style="
                        width: 450px;
                        margin-left: 20px;
                        margin-top: 10px;
                        height: 40px;
                        margin-top: 1px;
                      "
                    >
                      <v-select
                        @change="middleSubjectChange()"
                        :items="level1select_middle"
                        item-text="middleSubject"
                        item-value="middleSubject"
                        v-model="level1data.middleSubject"
                        style="font-family: notosansregular"
                        label="중단원을 선택해주세요."
                        outlined
                        dense
                        :disabled="!level1data.bigSubject"
                      ></v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      height: 30px;
                      font-family: notosansregular;
                      font-size: 17px;
                    "
                  >
                    소단원
                  </td>
                  <td>
                    <div
                      style="
                        width: 450px;
                        margin-left: 20px;
                        margin-top: 10px;
                        height: 40px;
                        margin-top: 1px;
                      "
                    >
                      <v-select
                        @change="smallSubjectChange()"
                        :items="level1select_small"
                        item-text="smallSubject"
                        item-value="smallSubject"
                        v-model="level1data.smallSubject"
                        style="font-family: notosansregular"
                        label="소단원을 선택해주세요."
                        outlined
                        dense
                        :disabled="!level1data.middleSubject"
                      ></v-select>
                    </div>
                  </td>
                </tr>
              </table>
              <!-- <v-btn
                @click="setSessionOne()"
                class="nextbtn"
                outlined
                style="
                  float: right;
                  margin-top: 20px;
                  font-size: 17px;
                  color: white;
                  margin-right: 20px;
                "
                width="110"
                height="45"
                >적용</v-btn
              > -->
            </div>
          </v-row>
        </div>
        <div class="level2popup">
          <!-- level2 Setting -->
          <v-row style="margin-top: 20px">
            <div class="popup" style="width: 800px; margin-left: 40px">
              <p
                style="
                  font-family: notosansregular;
                  font-size: 21px;
                  margin-bottom: 40px;
                "
              >
                2. 대화셋 선택
              </p>
              <table
                style="margin-top: -20px; margin-left: 130px; width: 600px"
              >
                <tr>
                  <td>
                    <p style="font-family: notosansregular; font-size: 17px">
                      유형
                    </p>
                  </td>
                  <td>
                    <div
                      style="width: 300px; margin-left: 10px; margin-top: 10px"
                    >
                      <v-select
                        :items="level2select"
                        @change="level2selectchange()"
                        v-model="level2data.type"
                        style="font-family: notosansregular"
                        label="유형을 선택해주세요."
                        outlined
                        dense
                        :disabled="level1data.smallSubject == ''"
                      ></v-select>
                    </div>
                  </td>
                </tr>
              </table>
              <div
                style="
                  width: 630px;
                  height: 310px;
                  overflow-y: scroll;
                  overflow-x: hidden;
                  border: 1px solid rgb(190, 190, 190);
                "
              >
                <table
                  style="
                    width: 600px;
                    font-family: notosansregular;
                    font-size: 15px;
                  "
                >
                  <tr>
                    <td style="width: 40px">
                      <input
                        v-model="level2datacheckall"
                        v-show="level2data.quizList.length > 0"
                        @click="quizallselect()"
                        class="large-checkbox"
                        style="margin-left: 10px; margin-top: 7px"
                        type="checkbox"
                      />
                    </td>
                    <td style="padding: 5px; font-size: 17px; width: 110px">
                      유형
                    </td>
                    <td style="padding: 5px; font-size: 17px">질의</td>
                    <td style="padding: 5px; font-size: 17px">응답</td>
                  </tr>
                  <tr v-for="(j, i) in level2data.quizList" :key="i">
                    <td style="width: 40px">
                      <input
                        v-model="level2data.quizList[i].check"
                        class="large-checkbox"
                        style="margin-left: 10px; margin-top: 7px"
                        type="checkbox"
                      />
                    </td>
                    <td style="padding: 5px; max-width: 80px; max-width: 100px">
                      {{ level2data.quizList[i].type }}
                    </td>
                    <td style="padding: 5px; max-width: 80px; max-width: 100px">
                      {{ level2data.quizList[i].question }}
                    </td>
                    <td
                      style="
                        padding: 5px;
                        padding-right: 15px;
                        min-width: 80px;
                        max-width: 100px;
                      "
                    >
                      {{ level2data.quizList[i].answer }}
                    </td>
                  </tr>
                </table>
              </div>
              <!-- <v-btn
                @click="setSessionTwo()"
                class="nextbtn"
                outlined
                style="
                  float: right;
                  margin-top: 20px;
                  font-size: 17px;
                  color: white;
                  margin-right: -0px;
                "
                width="110"
                height="45"
                >적용</v-btn
              > -->
            </div>
          </v-row>
        </div>
        <div class="level3popup" style="margin-top: -95px">
          <!-- level2 Setting -->
          <v-row style="margin-top: 30px">
            <div class="popup" style="margin-left: 30px">
              <p
                style="
                  font-family: notosansregular;
                  font-size: 21px;
                  margin-bottom: 40px;
                "
              >
                3. 대화 설정
              </p>
              <table style="margin-top: -20px; margin-left: 20px; width: 600px">
                <tr>
                  <td>
                    <p style="font-family: notosansregular; font-size: 17px">
                      대화 상대
                    </p>
                  </td>
                  <td>
                    <div
                      style="width: 450px; margin-left: 50px; margin-top: 10px"
                    >
                      <v-select
                        @change="changePersona()"
                        :items="level3selectone"
                        item-text="persona"
                        item-value="prompt"
                        v-model="level3data.persona_prompt"
                        style="font-family: notosansregular"
                        label="대화 상대를 선택해주세요."
                        outlined
                        dense
                      ></v-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style="font-family: notosansregular; font-size: 17px">
                      서술형 교수법
                    </p>
                  </td>
                  <td>
                    <div
                      style="width: 450px; margin-left: 50px; margin-top: 10px"
                    >
                      <v-select
                        @change="changeTeachType()"
                        :disabled="!level2data.type"
                        :items="level3selecttwo"
                        item-text="type"
                        item-value="prompt"
                        v-model="level3data.teachType_prompt"
                        style="font-family: notosansregular"
                        label="서술형 교수법을 선택해주세요."
                        outlined
                        dense
                      ></v-select>
                    </div>
                  </td>
                </tr>
              </table>
              <!-- <v-btn
                @click="setSessionThree()"
                class="nextbtn"
                outlined
                style="
                  float: right;
                  margin-top: 20px;
                  font-size: 17px;
                  color: white;
                  margin-right: -0px;
                "
                width="110"
                height="45"
                >적용</v-btn
              > -->
            </div>
          </v-row>
        </div>
        <v-btn
          @click="savePreset()"
          outlined
          style="
            margin-left: 210px;
            margin-top: -40px;
            background-color: green;
            font-family: notosansregular;
            color: white;
          "
          >프리셋 저장</v-btn
        >
        <div
          class="level3popup"
          style="overflow-y: scroll; border-radius: 5px; width: 1000px"
        >
          <table
            style="width: 100%; border-collapse: collapse; text-align: center"
          >
            <tr>
              <td class="tabletd">학년 - 학기</td>
              <td class="tabletd">과목</td>
              <td class="tabletd">소단원</td>
              <td class="tabletd">유형</td>
              <td class="tabletd">대화 상대</td>
              <td class="tabletd">교수 방법</td>
              <td class="tabletd">삭제</td>
            </tr>
            <tr v-for="(j, i) in presets" :key="i" class="trbtn">
              <td class="tabletd" @click="loadPreset(i)">{{ j.gradeInfo }}</td>
              <td class="tabletd" @click="loadPreset(i)">{{ j.subject }}</td>
              <td class="tabletd" @click="loadPreset(i)">
                {{ j.smallSubject }}
              </td>
              <td class="tabletd" @click="loadPreset(i)">{{ j.type }}</td>
              <td class="tabletd" @click="loadPreset(i)">{{ j.persona }}</td>
              <td class="tabletd" @click="loadPreset(i)">{{ j.teachType }}</td>
              <td class="tabletd">
                <v-btn
                  @click="deletePreset(i)"
                  outlined
                  style="
                    background-color: red;
                    color: white;
                    font-family: notosansregular;
                  "
                  >삭제</v-btn
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div style="float: right; margin-top: 75px; margin-left: -300px">
        <div
          class="level4popup"
          style="transform: scale(0.85); transform-origin: 0 0"
        >
          <v-row justify="center" style="margin-top: 30px">
            <v-btn
              @click="talkStart()"
              style="
                font-size: 18px;
                font-family: notosansregular;
                background-color: #4751af;
                color: white;
              "
              width="300"
              height="50"
              outlined
              >채팅 시작</v-btn
            >
          </v-row>
          <v-row justify="center" style="margin-top: 30px">
            <v-btn
              @click="urlcopy()"
              style="
                font-size: 18px;
                font-family: notosansregular;
                background-color: rgb(240, 240, 240);
              "
              width="300"
              height="50"
              outlined
              >채팅방 URL 복사하기</v-btn
            >
          </v-row>
          <v-row justify="center" style="margin-top: 30px">
            <v-btn
              @click="qrrender()"
              style="
                font-size: 18px;
                font-family: notosansregular;
                background-color: rgb(240, 240, 240);
              "
              width="300"
              height="50"
              outlined
              >채팅방 QR코드 표시</v-btn
            >
          </v-row>
        </div>
        <div
          v-if="isTalkStart === true"
          id="chatroom"
          style="
            width: 700px;
            height: 710px;
            margin-top: -60px;
            margin-left: -10px;
            overflow-y: hidden;
          "
        >
          <iframe
            :src="`${innerURL}`"
            frameborder="0"
            style="width: 700px; height: 700px"
            scrolling="no"
          ></iframe>
        </div>
      </div>
      <v-dialog v-model="isqr" width="700">
        <vue-qr
          style="border: 20px solid #ffb897"
          :logoSrc="`${origin}/pencil5.png`"
          :logoScale="0.2"
          :logoCornerRadius="50"
          :correctLevel="3"
          :text="`${innerURL}`"
          :size="700"
        ></vue-qr>
      </v-dialog>
    </v-row>
    <v-row>
      <div style="height: 100px"></div>
    </v-row>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import {
  createPreset,
  createTutorSession,
  deletePreset,
  getEndSentence,
  getprompt,
  getSelect1,
  getSelect2,
  getSelect3one,
  getSelect3Three,
  getSelect3two,
  getTutorPrompt,
  setSession1,
  setSession2,
  setSession3,
  getPresetList,
} from "../api/api-list";
import VueQr from "vue-qr";

export default {
  async created() {
    const session_id = uuidv4();
    sessionStorage.setItem("sessionId", session_id);
    this.sessionId = session_id;
    this.origin = window.location.origin;

    const path = this.$route.path;
    if (path.includes("save")) {
      this.isTest = true;
    }

    if (this.isTest === true) {
      this.innerURL = `${this.origin}/save/inner/talk/${session_id}`;
    } else {
      this.innerURL = `${this.origin}/inner/talk/${session_id}`;
    }

    this.loadSelect();
  },
  components: {
    VueQr,
  },
  data() {
    return {
      presets: [],
      isTalkStart: false,
      origin: "",
      sessionId: "",
      isqr: false,
      level: 0,
      level1data: {
        gradeInfo: "",
        subject: "",
        bigSubject: "",
        middleSubject: "",
        smallSubject: "",
      },
      level2data: {
        type: "",
        quizList: [],
      },
      level3data: {
        persona_prompt: "",
        teachType_prompt: "",
      },
      level1selectall: [],
      level1select: [],
      level1select_gradeInfo: [],
      level1select_subject: [],
      level1select_big: [],
      level1select_middle: [],
      level1select_small: [],
      level2select: [],
      level3select: [],
      level2selectall: [],
      quizListSelect: [],
      quizList: [],
      level3selectone: [],
      level3selecttwo: [],
      level3selecttwoall: [],
      level3selectthree: "",
      level2datacheckall: false,
      endSentence: "",
      isTest: false,
      innerURL: "",
      persona: "",
      teachType: "",
    };
  },
  methods: {
    changePersona() {
      const selectedItem = this.level3selectone.find(
        (item) => item.prompt === this.level3data.persona_prompt
      );

      if (selectedItem) {
        // item-text 값 추출
        const selectedText = selectedItem.persona;

        // 필요시 상태 업데이트
        this.persona = selectedText;
      }
    },
    changeTeachType() {
      const selectedItem = this.level3selecttwo.find(
        (item) => item.prompt === this.level3data.teachType_prompt
      );

      if (selectedItem) {
        // item-text 값 추출
        const selectedText = selectedItem.type;

        // 필요시 상태 업데이트
        this.teachType = selectedText;
      }
    },
    async savePreset() {
      const id = uuidv4();
      const { gradeInfo, subject, bigSubject, middleSubject, smallSubject } =
        this.level1data;
      const { type, quizList } = this.level2data;
      const { persona_prompt, teachType_prompt } = this.level3data;
      const persona = this.persona;
      const teachType = this.teachType;

      const tempList = quizList.filter((item) => item.check === true);

      if (
        !gradeInfo ||
        !subject ||
        !bigSubject ||
        !middleSubject ||
        !smallSubject ||
        !type ||
        !tempList.length ||
        !persona_prompt ||
        !teachType_prompt
      ) {
        alert("모든 선택을 완료해주세요.");
        return;
      }

      const data = {
        id,
        gradeInfo,
        subject,
        bigSubject,
        middleSubject,
        smallSubject,
        type,
        quizList,
        persona,
        teachType,
        persona_prompt,
        teachType_prompt,
      };

      await createPreset(data);
      this.presets.push(data);
    },
    loadPreset(index) {
      const target = this.presets[index];
      const {
        gradeInfo,
        subject,
        bigSubject,
        middleSubject,
        smallSubject,
        type,
        quizList,
        persona_prompt,
        teachType_prompt,
      } = target;

      this.level2select = ["모두", ...this.level2select];
      this.level2datacheckall = false;
      for (let i = 0; i < quizList.length; i++) {
        quizList[i].check = false;
      }

      this.level1data = {
        gradeInfo,
        subject,
        bigSubject,
        middleSubject,
        smallSubject,
      };

      this.level2data = {
        type,
        quizList,
      };

      this.level3data = {
        persona_prompt,
        teachType_prompt,
      };
    },
    async deletePreset(index) {
      const id = this.presets[index]["id"];
      this.presets.splice(index, 1);
      await deletePreset(id);
    },
    isStart() {
      const { gradeInfo, subject, bigSubject, middleSubject, smallSubject } =
        this.level1data;
      const { quizList } = this.level2data;
      const { persona_prompt, teachType_prompt } = this.level3data;

      const isAllFilled = [
        gradeInfo,
        subject,
        bigSubject,
        middleSubject,
        smallSubject,
        quizList,
        persona_prompt,
        teachType_prompt,
      ].every((item) => item.length !== 0);

      return isAllFilled;
    },
    async isStart2() {
      const { prompt } = (
        await getTutorPrompt(sessionStorage.getItem("sessionId"))
      ).data;

      if (!prompt || prompt === "error") {
        return false;
      } else {
        return true;
      }
    },
    async talkStart() {
      const res1 = await this.setSessionOne();
      const res2 = await this.setSessionTwo();
      const res3 = await this.setSessionThree();

      if (!res1) {
        alert("모든 단원 설정을 완료해주세요.");
        return;
      } else if (!res2) {
        alert("모든 문제 설정을 완료해주세요.");
        return;
      } else if (!res3) {
        alert("모든 대화 설정을 완료해주세요.");
        return;
      } else {
        this.isTalkStart = true;
        return;
      }
    },
    async urlcopy() {
      const res = await this.isStart2();
      if (!res) {
        alert("모든 설정을 완료해주세요.");
        return;
      }
      let message = `${this.origin}/talk/${sessionStorage.getItem(
        "sessionId"
      )}`;

      const path = this.$route.path;
      if (path.includes("save")) {
        message = `${this.origin}/save/talk/${sessionStorage.getItem(
          "sessionId"
        )}`;
      }

      navigator.clipboard.writeText(message);
      alert(`URL이 복사되었습니다.\n\n${message}`);
    },
    async qrrender() {
      const res = await this.isStart2();

      if (!res) {
        alert("모든 설정을 완료해주세요.");
        return;
      } else {
        this.isqr = true;
      }
    },
    error(text) {
      window.dispatchEvent(
        new CustomEvent("error", {
          detail: {
            status: true,
            text,
          },
        })
      );
    },
    success(text) {
      window.dispatchEvent(
        new CustomEvent("success", {
          detail: {
            status: true,
            text,
          },
        })
      );
    },
    level1ok() {
      const data = this.level1data;
      const { gradeInfo, subject, bigSubject, middleSubject, smallSubject } =
        data;

      if (gradeInfo && subject && bigSubject && middleSubject && smallSubject) {
        // 적용 저장
        this.level = 2;
        return;
      } else {
        // 모든 것을 선택해주세용 ~

        alert("모든 항목을 선택해주세요.");
      }
    },
    level2ok() {
      const quizList = this.level2data.quizList.filter(
        (item) => item.check === true
      );

      if (quizList.length) {
        this.level = 3;
        // next
        return;
      } else {
        alert("문제를 선택해주세요.");

        // retry
      }
    },
    level3ok() {
      const data = this.level3data;
      const { persona_prompt, teachType_prompt } = data;

      if (persona_prompt && teachType_prompt) {
        //next
        // 대화를 생성하시겠습니까 ?
        // 대화 생성 로딩 ...
        //
        this.createSession();
        return;
      } else {
        alert("모든 항목을 선택해주세요.");
        //retry
      }
    },
    async loadSelect() {
      const one = await getSelect1();
      const two = await getSelect2();
      const three = await getSelect3one();
      const four = await getSelect3two();
      const five = await getSelect3Three();

      const oneResult = one.data.result;
      this.level1selectall = oneResult.filter((item) => item.gradeInfo !== "");
      this.level1select = this.level1selectall;
      const res = two.data.result;
      const two_titles = [...new Set(res.map((item) => item.type))];
      this.level2select = two_titles.filter(
        (item) => item !== "" && item !== null && item !== undefined
      );
      this.level2selectall = res;

      this.level3selectone = three.data.result;
      this.level3data.persona_prompt = this.level3selectone[0].prompt;
      this.level3selecttwo = four.data.result;
      this.level3selecttwoall = four.data.result;
      this.level3selectthree = five.data.result;

      this.persona = this.level3selectone[0].persona;
      //this.teachType = this.level3selecttwo[0].type;

      this.level1select_gradeInfo = this.level1selectall;
      this.level1select_subject = this.level1selectall;
      this.level1select_big = this.level1selectall;
      this.level1select_middle = this.level1selectall;
      this.level1select_small = this.level1selectall;

      if (oneResult.length) {
        const res = (await getPresetList()).data;

        this.presets = res;
      }
    },
    level2selectchange() {
      //this.level3selecttwo = this.level3selecttwoall;
      this.level2datacheckall = false;
      const data = this.level2selectall;
      const type = this.level2data.type;

      if (type === "모두") {
        const compareTypes = this.level2select.slice(1);

        const data2 = data.filter(
          (item) =>
            compareTypes.includes(item.type) &&
            item.subject.replace(/(\s*)/g, "") ===
              this.level1data.subject.replace(/(\s*)/g, "") &&
            item.bigSubject.replace(/(\s*)/g, "") ===
              this.level1data.bigSubject.replace(/(\s*)/g, "") &&
            item.middleSubject.replace(/(\s*)/g, "") ===
              this.level1data.middleSubject.replace(/(\s*)/g, "") &&
            item.smallSubject.replace(/(\s*)/g, "") ===
              this.level1data.smallSubject.replace(/(\s*)/g, "")
        );
        const quizList = data2.map((item) => {
          const res = {
            type: item.type,
            question: item.question,
            answer: item.answer,
            check: false,
          };

          return res;
        });

        // const compare = this.level3selectthree;

        // let result = [];

        // for (let i = 0; i < compare["모두"].length; i++) {
        //   const target = compare["모두"][i];
        //   const element = this.level3selecttwo.find(
        //     (item) => item.teachType === target
        //   );
        //   result.push(element);
        // }

        //let targetPrompt = result[0].prompt;

        //this.level3selecttwo = result;
        //this.level3data.teachType_prompt = targetPrompt;

        this.level2data.quizList = quizList;
      } else {
        const data2 = data.filter(
          (item) =>
            item.type === type &&
            item.subject.replace(/(\s*)/g, "") ===
              this.level1data.subject.replace(/(\s*)/g, "") &&
            item.bigSubject.replace(/(\s*)/g, "") ===
              this.level1data.bigSubject.replace(/(\s*)/g, "") &&
            item.middleSubject.replace(/(\s*)/g, "") ===
              this.level1data.middleSubject.replace(/(\s*)/g, "") &&
            item.smallSubject.replace(/(\s*)/g, "") ===
              this.level1data.smallSubject.replace(/(\s*)/g, "")
        );
        const quizList = data2.map((item) => {
          const res = {
            type: item.type,
            question: item.question,
            answer: item.answer,
            check: false,
          };

          return res;
        });

        // const compare = this.level3selectthree[type];
        // let result = [];
        // for (let i = 0; i < this.level3selecttwo.length; i++) {
        //   if (compare.includes(this.level3selecttwo[i].teachType)) {
        //     result.push(this.level3selecttwo[i]);
        //   } else {
        //     continue;
        //   }
        // }

        // let targetPrompt = this.level3selecttwo.filter(
        //   (item) => item.teachType === compare[0]
        // )[0].prompt;

        //this.level3selecttwo = result;
        //this.level3data.teachType_prompt = targetPrompt;

        this.level2data.quizList = quizList;
      }
    },
    gradeInfochange() {
      const { gradeInfo } = this.level1data;

      this.level1select_subject = this.level1selectall.filter(
        (item) => item.gradeInfo === gradeInfo
      );
      this.level1select_big = this.level1selectall.filter(
        (item) => item.gradeInfo === gradeInfo
      );
      this.level1select_middle = this.level1selectall.filter(
        (item) => item.gradeInfo === gradeInfo
      );
      this.level1select_small = this.level1selectall.filter(
        (item) => item.gradeInfo === gradeInfo
      );
    },
    subjectchange() {
      const { subject, gradeInfo } = this.level1data;

      // this.level1select_gradeInfo = this.level1selectall.filter(
      //   (item) => item.subject === subject
      // );
      this.level1select_big = this.level1selectall.filter(
        (item) => item.subject === subject && item.gradeInfo === gradeInfo
      );
      this.level1select_middle = this.level1selectall.filter(
        (item) => item.subject === subject && item.gradeInfo === gradeInfo
      );
      this.level1select_small = this.level1selectall.filter(
        (item) => item.subject === subject && item.gradeInfo === gradeInfo
      );

      this.level2data.type = "";
      this.level2data.quizList = [];
      this.level2datacheckall = false;
      this.level1data.bigSubject = "";
      this.level1data.middleSubject = "";
      this.level1data.smallSubject = "";
    },
    bigSubjectChange() {
      const { bigSubject } = this.level1data;
      this.level1select_middle = this.level1selectall.filter(
        (item) => item.bigSubject === bigSubject
      );
      this.level2data.type = "";
      this.level2data.quizList = [];
      this.level1data.middleSubject = "";
      this.level1data.smallSubject = "";
    },
    middleSubjectChange() {
      const { middleSubject } = this.level1data;
      this.level1select_small = this.level1selectall.filter(
        (item) => item.middleSubject === middleSubject
      );
      this.level2data.type = "";
      this.level2data.quizList = [];
      this.level1data.smallSubject = "";
    },
    smallSubjectChange() {
      const { smallSubject } = this.level1data;
      // this.level1select = this.level1selectall.filter(
      //   (item) => item.smallSubject === smallSubject
      // );
      const arr = this.level2selectall.filter(
        (item) => item.smallSubject === smallSubject
      );
      const two_titles = ["모두", ...new Set(arr.map((item) => item.type))];

      this.level2select = two_titles;

      this.level2data.type = "";
      this.level2data.quizList = [];
    },
    async createSession() {
      const { quizList } = this.level2data;
      const { persona_prompt, teachType_prompt } = this.level3data;

      const data = {
        sessionId: sessionStorage.getItem("sessionId"),
        lessonSelect: this.level1data,
        role_prompt: persona_prompt,
        teachType_prompt,
        quizList: quizList.filter((item) => item.check === true),
      };

      const res = (await createTutorSession(data)).data.result;
      if (res) {
        alert("대화 세션 생성이 완료되었습니다.");
        this.level = 4;
      } else {
        alert("대화 세션 생성에 실패하였습니다.");
      }
    },
    async setSessionOne() {
      const { gradeInfo, subject, bigSubject, middleSubject, smallSubject } =
        this.level1data;
      if (
        !gradeInfo ||
        !subject ||
        !bigSubject ||
        !middleSubject ||
        !smallSubject
      ) {
        return false;
      }

      const data = {
        sessionId: sessionStorage.getItem("sessionId"),
        lessonSelect: this.level1data,
      };
      const res = await setSession1(data);

      return res.data.result;
    },
    async setSessionTwo() {
      const quizList = this.level2data.quizList.filter(
        (item) => item.check === true
      );

      if (!quizList.length) {
        return false;
      }

      const res = await setSession2({
        sessionId: sessionStorage.getItem("sessionId"),
        quizList,
      });

      return res.data.result;
    },
    async setSessionThree() {
      if (
        !this.level3data.persona_prompt ||
        !this.level3data.teachType_prompt
      ) {
        return false;
      }

      const res = await setSession3({
        sessionId: sessionStorage.getItem("sessionId"),
        role_prompt: this.level3data.persona_prompt,
        teachType_prompt: this.level3data.teachType_prompt,
      });

      return res.data.result;
    },
    async setNewPrompt() {
      // const sessionId = sessionStorage.getItem("sessionId");
      // const prompt = (await getprompt(sessionId)).data.prompt;
      // if (prompt === "error") {
      //   alert("error");
      // } else {
      //   // 새 프롬프트 ..?
      // }
    },
    quizallselect() {
      if (this.level2datacheckall === true) {
        this.level2datacheckall = false;
        for (let i = 0; i < this.level2data.quizList.length; i++) {
          this.level2data.quizList[i].check = false;
        }
      } else {
        this.level2datacheckall = true;
        for (let i = 0; i < this.level2data.quizList.length; i++) {
          this.level2data.quizList[i].check = true;
        }
      }

      return;
    },
  },
  watch: {
    level1data: {
      handler() {
        this.isTalkStart = false;
        if (this.level2data.quizList.length === 0) {
          this.level2datacheckall = false;
        }
      },
      deep: true,
    },
    level2data: {
      handler() {
        this.isTalkStart = false;
      },
      deep: true,
    },

    level3data: {
      handler() {
        this.isTalkStart = false;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
/* .background {
  background-color: beige;
  width: 100%;
} */

.startPopup {
  width: 500px;
  height: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  border: 1px solid rgb(190, 190, 190);
  background-color: white;
}

.level1popup {
  width: 650px;
  height: 370px;
  border-radius: 25px;
  border: 1px solid rgb(190, 190, 190);
  background-color: white;
  transform: scale(0.85);
  transform-origin: 0% 0%;
}

.level2popup {
  width: 690px;
  height: 500px;
  border-radius: 25px;
  border: 1px solid rgb(190, 190, 190);
  background-color: white;
  transform: scale(0.8);
  transform-origin: 0% 0%;
  margin-top: -50px;
}

.level3popup {
  width: 650px;
  height: 270px;
  border-radius: 25px;
  border: 1px solid rgb(190, 190, 190);
  background-color: white;
  transform: scale(0.85);
  transform-origin: 0% 0%;
}

.level4popup {
  width: 500px;
  height: 250px;
  border-radius: 25px;
  border: 1px solid rgb(190, 190, 190);
  background-color: white;
}

.qrpopup {
  width: 300px;
  height: 430px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  border: 1px solid rgb(190, 190, 190);
  background-color: white;
}

.startbtn {
  font-family: notosansregular;
  border: none;
  background-color: #3d90ef;
}

.nextbtn {
  font-family: notosansregular;
  background-color: #3d90ef;
}

.startbtn:hover {
  transform: scale(1.1);
}

.popup {
  width: 600px;
  height: 460px;
  text-align: left;
}

.large-checkbox {
  width: 20px;
  height: 20px;
}

.tabletd {
  padding: 5px;
  border: 1px solid black;
}

.trbtn {
  cursor: pointer;
}

.trbtn:hover {
  background-color: rgb(180, 180, 180);
}
</style>
